@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .main-menu ul li a {
    padding: 30px 18px; }
  .all-testimonial .owl-controls {
    top: 50%; }
  .all-testimonial .owl-controls .owl-next {
    right: -46px; }
  .all-testimonial .owl-controls .owl-prev {
    left: -46px; }
  .sngl-client {
    margin: 10px; }
  .special-service-sec .inner:nth-child(3n+1) {
    clear: both; }
  .sec-title {
    margin: 0 100px 60px; }
  .slider-text {
    padding: 0 65px; }
  .main-menu ul ul {
    top: 89px; }
  .service-item .inner:nth-child(3n+1) {
    clear: both; } }

@media only screen and (max-width: 767px) {
  .logo {
    padding-bottom: 0;
    text-align: center; }
  .sec-title {
    margin: 0 0 60px; }
  .counting_sl {
    margin: 40px 0; }
  .faq-img {
    float: none;
    text-align: center;
    width: 100%;
    margin-top: 25px; }
  .special-service-sec .inner:nth-child(2n+1) {
    clear: both; }
  .all-testimonial .owl-controls {
    top: 50%; }
  .all-testimonial .owl-controls .owl-next {
    right: -8px; }
  .all-testimonial .owl-controls .owl-prev {
    left: -8px; }
  .single-testimonial {
    margin: 0px 54px; }
  .client-comment li a {
    margin: 0 2px; }
  .footer-sec .col-md-3.col-sm-6 {
    margin-bottom: 25px;
    width: 100%;
    display: inline-block; }
  .faq-img {
    margin-top: 50px; }
  .slider-text {
    padding: 0 55px; }
  .about img {
    margin-bottom: 30px; } }

@media only screen and (min-width: 768px) and (max-width: 991px) {
  body {
    font-size: 15px;
    line-height: 24px; }
  h1 {
    font-size: 22px; }
  h2 {
    font-size: 18px; }
  h3 {
    font-size: 16px; }
  .main-menu ul {
    float: none;
    text-align: center; }
  .logo {
    padding-bottom: 0;
    text-align: center; }
  .sec-title {
    margin: 0 0 60px; }
  .counting_sl {
    margin: 40px 0; }
  .faq-img {
    float: none;
    text-align: center;
    width: 100%;
    margin-top: 25px; }
  .special-service-sec .inner:nth-child(2n+1) {
    clear: both; }
  .all-testimonial .owl-controls {
    top: 50%; }
  .all-testimonial .owl-controls .owl-next {
    right: -22px; }
  .all-testimonial .owl-controls .owl-prev {
    left: -22px; }
  .client-comment li a {
    margin: 0 2px; }
  .footer-sec .col-md-3.col-sm-6 {
    margin-bottom: 25px; }
  .contact-person {
    padding: 0px 15px; }
  .faq-img {
    margin-top: 50px; }
  .slider-text {
    padding: 0 55px; }
  .all-slide .single-slide {
    background-size: 100% 100%; }
  .about img {
    margin-bottom: 30px; }
  .slider-text p {
    padding: 0 0px; }
  .newsletter-right input[type="email"] {
    padding: 10px 4px; }
  .newsletter-left h1 {
    font-size: 18px; }
  .newsletter-right {
    margin-top: 19px; }
  .youtube-video {
    margin-bottom: 20px; }
  .about-sec img {
    margin-bottom: 20px; }
  .service-item .inner:nth-child(2n+1) {
    clear: both; }
  .footer-sec .col-md-3.col-sm-6:nth-child(2n+1) {
    clear: both; }
  .about-img {
    margin-bottom: 20px; } }

@media only screen and (min-width: 0px) and (max-width: 470px) {
  .hd-lft li::before {
    display: none; }
  .hd-lft li {
    padding: 0px 6px; }
  .hd-lft li i {
    margin-right: 4px; }
  .hd-sec .container {
    padding: 0; }
  .slider-text {
    padding: 0; }
  .slider-text p {
    color: #fff;
    padding: 0 100px; }
  .slider-text li:last-child a {
    margin-top: 15px; }
  .all-slide .owl-item {
    height: 600px; }
  .newsletter-right input[type="email"] {
    padding: 10px;
    width: 140px; } }

@media only screen and (min-width: 0px) and (max-width: 767px) {
  body {
    font-size: 14px;
    line-height: 24px; }
  h1 {
    font-size: 22px; }
  h2 {
    font-size: 18px; }
  h3 {
    font-size: 16px; }
  .container {
    overflow: hidden; }
  .hd-lft {
    display: inline-block;
    text-align: center;
    width: 100%;
    margin-bottom: 10px; }
  .hd-rgt {
    float: none;
    text-align: center; }
  .hd-sec {
    display: inline-block;
    width: 100%;
    padding: 14px 0; }
  .logo {
    padding: 28px 0; }
  .mean-container .mean-nav ul li a i {
    display: none; }
  .contact-person {
    padding: 0px; }
  .slider-text {
    padding: 0 60px; }
  .slider-text h1 {
    font-size: 16px; }
  .slider-text h2 {
    font-size: 16px; }
  .slider-text p {
    padding: 0; }
  .abt-lft {
    margin-bottom: 20px; }
  .newsletter-left {
    text-align: center; }
  .newsletter-right {
    float: none;
    text-align: center;
    margin-top: 25px; }
  .youtube-video {
    margin-bottom: 20px; }
  .about-sec img {
    margin-bottom: 20px; }
  .page-heading ul {
    float: none;
    text-align: center; }
  .page-heading h1 {
    text-align: center;
    margin-bottom: 20px; }
  .call-to-action-text h2 {
    font-size: 20px; }
  .call-to-action-text a.btn {
    float: none; }
  .call-to-action-text {
    text-align: center;
    margin-top: 10px; }
  .about-img {
    margin-bottom: 20px; }
  .contact-field h2, .map-heading h2 {
    margin: 0; }
  .contact-field .col-md-4.col-sm-4.col-xs-12 {
    padding: 0; }
  .contact-field .message-input {
    padding: 0; } }

@media only screen and (min-width: 0px) and (max-width: 450px) {
  .faq-img {
    margin-top: 50px; } }

@media only screen and (min-width: 0px) and (max-width: 380px) {
  .hd-rgt {
    float: none;
    text-align: center;
    margin-bottom: 8px; }
  .hd-lft li {
    padding: 5px 0;
    width: 100%; } }

@media only screen and (min-width: 0px) and (max-width: 359px) {
  .single-testimonial {
    margin: 0px; }
  .client-comment p {
    padding: 0px 54px; } }
